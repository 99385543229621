import { Autocomplete, TextField } from '@mui/material'
import './SelectInstrument.css'
import React from 'react'

const SelectInstrument = ({placeholder = undefined, list = null, loading = false, onChange = (e)=>{}, getList = null, width= null, disabled=false, value=undefined, label=undefined }) => {

    return (
        <Autocomplete
            clearOnEscape
            clearOnBlur
            size='small'
            value={value}
            onChange={(e, v) => { onChange(v)  }}
            loading={loading}
            disabled={disabled}
            loadingText={`Just a sec...`}
            // noOptionsText={`Type in something...`}
            options={list ?? []}
            sx={{ width: width ?? 300 }}
            isOptionEqualToValue={(d)=>true}
            renderInput={(params) => <TextField label={label} onChange={(e) => getList ? getList(e.target.value) : null} placeholder={placeholder ?? 'Select instrument...'}  {...params} />}
        />
    )
}

export default SelectInstrument;