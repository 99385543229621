// src/components/AppBar.js
import React, { useState } from 'react';
import './AppBar.css';
import { AppBar, Toolbar, Button, Tabs, Tab, Box, IconButton, Avatar, Menu, MenuItem, Tooltip, List, ListItem, Divider, ListItemText, Modal } from '@mui/material';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useSelector, useDispatch } from 'react-redux';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { logoutUser } from '../../features/slices/users/userSlice';
import { tradeUsers, setCurrentUser } from '../../features/slices/trade_user/tradeUserSlice';
import { setTab } from '../../features/slices/tabs/tabSlice';
import Popper from '@mui/material/Popper';
import {SocketContext}  from '../../utils/SocketContext';
import { readNotification } from '../../features/slices/alert/TransitionAlertsSlice';
import Strategy from '../Pages/Strategy/Strategy';
import Events from '../Pages/Events/Events';
import News from '../Pages/News/News';
import logo from '../../assets/img/eliteDelta_logo.png';
import FeedBackModal from '../Modal/FeedBackModal';

const TopAppBar = () => {
  
  const dispatch = useDispatch();
  const { disconnectSocket, connectUserSocket, disconnectUserSocket, balances } = React.useContext(SocketContext);
  const loginData = useSelector((state) => state.user.loginData);
  const currentUser = useSelector((state) => state.tradeUsers.currentUser);
  const tradeUser = useSelector((state) => state.tradeUsers.users);
  // const balances = useSelector((state) => state.tradeUsers.balances);
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [userData, setUserData] = useState({name: "User", pic: ""})
  const currentTab  = useSelector((state) => state.tab.tab);
  const notifications  = useSelector((state) => state.notifications.notification);
  const [anchorNotification, setAnchorNotification] = React.useState(null);
  const [feedBoxOpen, setFeedBoxOpen] = React.useState(false)
  const [openModal, setOpenModal] = React.useState({strategy: false, news: false, eve: false});
  const [topModal, setTopModal] = React.useState();
  
  const closeUserList = () => {
    setAnchorElUser(null);
  };
  const openUserList = (event) => {
    setAnchorElUser(anchorEl ? null : event.currentTarget);
  };

  const changeCurrentUser = (userId) => {
    dispatch(setCurrentUser(userId))
  }

  React.useEffect(()=>{
    if(loginData){
      if(loginData.name){
        if(loginData.id === "1" || loginData.id === "2"){
          dispatch(tradeUsers());
        }
        setUserData((u)=> ({...u, name: loginData.name}))
      }
      if(loginData.profile_pic_url){
        setUserData((u)=> ({...u, pic: loginData.profile_pic_url}))
      }
    }
  }, [loginData, dispatch])

  React.useEffect(()=>{
   if (currentUser){
    connectUserSocket();
   }
   return () =>{
    if (currentUser)
      disconnectUserSocket();
   }
  }, [currentUser])

  const handleChange = (_, newValue) => {
    if (newValue === "str") setOpenModal({...openModal, strategy: !openModal.strategy});
    else if (newValue === "news") setOpenModal({...openModal, news: !openModal.news});
    else if (newValue === "eve") setOpenModal({...openModal, eve: !openModal.eve});
    else dispatch(setTab(newValue));
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // Handle logout logic here
    dispatch(logoutUser())
    disconnectSocket();
    handleMenuClose();
  };

  const handleOpenModal = React.useCallback((key) => {
    setOpenModal({...openModal, [key]: !openModal[key]});
  }, [openModal]);

  return (
    <>
      { currentUser ? 
        <Box position={'fixed'} margin={'0.4rem 0 0 0.4rem'} zIndex={9999}>
          <Button size='small' variant={'contained'} sx={{fontSize: '1.5rem'}} id="userName" onClick={openUserList}>{currentUser}</Button>
          <Menu keepMounted={true} anchorEl={anchorElUser} open={Boolean(anchorElUser)} onClose={closeUserList} anchorOrigin={{ vertical: 'bottom', horizontal:'left'}} transformOrigin={{ vertical: 'top', horizontal: 'left'}}>
            { 
              tradeUser.map((d,ulI)=> (
                <MenuItem sx={{ minWidth: '150px', justifyContent: 'center', border: '1px solid #007fff', color: balances?.[d]?.balance > 0 ? "#22c55e" : "#f72b2b" }} disabled={currentUser === d } key={`userChangeMenuList_${ulI}`} onClick={() => { changeCurrentUser(d); closeUserList(); } }>{d.toUpperCase()} {balances?.[d]?.balance}</MenuItem>
              ))
            }
          </Menu>
        </Box>
        : 
        <Box sx={{width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', padding: '0px'}}>
          <div className="slide-text">Disclaimer: This platform is currently under development and only for testing purposes, and all the data provided here in not designed for public broadcast and not guaranteed for accuracy. We are not SEBI registered, and the information provided here should not be considered as buy or sell recommendations. Please trade at your own discretion and risk !</div>
        </Box>
      }
      <AppBar position="sticky" sx={{zIndex: 1301}}>
        <Toolbar>
          <img alt='VolEdge' title="VolEdge" width={50} height={30} src={logo} />
          <Box sx={{ width: '50%'}}>
            <Tabs sx={{ m: 'auto 1rem' }} value={currentTab} onChange={handleChange} textColor="secondary" indicatorColor="secondary" variant={"scrollable"} >
              <Tab value="live" label="Live" />
              <Tab value="str" label="Strategy" />
              <Tab value="mwpl" label="MWPL" />
              <Tab value="eve" label="Events" />
              <Tab value="flo" label="Flows" />
              <Tab value="ale" label="Alert" />
              {/* <Tab value="pcr" label="PCR" /> */}
              {/* <Tab value="his" label="Historical" /> */}
              <Tab value="oi_a" label="OI Analysis" />
              <Tab value="news" label="News" />
              {/* <Button sx={{m: 'auto 0px auto 10px', height: '60%'}} variant='outlined' size='small' onClick={()=>{setFeedBoxOpen(!feedBoxOpen)}}>Suggestion</Button> */}
            </Tabs>
          </Box>
          <Box sx={{p:0, display:'flex', justifyContent:'flex-end', width:'100%'}}>
           
            {/* <IconButton color="inherit" onClick={themeToggle}>
              {mode === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
            </IconButton> */}

            <IconButton color={notifications.some(c => !c.read) ? 'error': 'white'} onClick={(e)=>{ setAnchorNotification(e.currentTarget); dispatch(readNotification()) }}>
              <NotificationsIcon />
            </IconButton>

            <Popper placement="bottom-end" disablePortal={false} open={Boolean(anchorNotification)} anchorEl={anchorNotification} modifiers={[
                {
                  name: 'flip',
                  enabled: true,
                  options: {
                    altBoundary: false,
                    rootBoundary: 'document',
                    padding: 8,
                    },
                    },
                {
                  name: 'preventOverflow',
                  enabled: true,
                  options: {
                    altAxis: true,
                    altBoundary: true,
                    tether: true,
                    rootBoundary: 'document',
                    padding: 8,
                  },
                  },
              ]}
              >
              <ClickAwayListener onClickAway={()=> setAnchorNotification(null)}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', position: 'relative', overflow: 'auto', maxHeight: 300, '& ul': { padding: 0 },}} subheader={<li />}>
                  {
                    !notifications.length ? 
                      <ListItem alignItems="flex-start">
                        <ListItemText primary={"Nothing new here..."} />
                      </ListItem> :
                    notifications.map((d, i)=>
                      <>
                        <ListItem alignItems="flex-start">
                          <ListItemText primary={d.message} />
                        </ListItem>
                        {
                          i !== notifications.length -1 ? <Divider variant="middle" component="li" /> : null
                        }
                      </>
                    )
                  }
                </List>
                {/* <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper', marginTop: 2.5, minHeight:'400px', maxHeight:'400px',minWidth:'250px', maxWidth:'250px' }}>
                  {
                    !notifications.length ? <Typography>No new Notifications here...</Typography> :
                    notifications.map((d)=>
                      <Typography>{d.message}</Typography>
                    )
                  }
                </Box> */}

              </ClickAwayListener>
            </Popper>

            <Tooltip title="Account settings">
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleMenuOpen}
              >
                <Avatar alt={userData.name} src={userData.pic} />
                <ArrowDropDownIcon />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => setFeedBoxOpen(!feedBoxOpen)}>Drop a Suggestion</MenuItem>
              <MenuItem onClick={() => handleChange(null, 'chg_pw')}>Change Password</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <FeedBackModal open={feedBoxOpen} handleClose={()=>{setFeedBoxOpen(!feedBoxOpen)}}/>
      <Modal open={openModal.strategy || openModal.news || openModal.eve} keepMounted>
        <Box sx={{height: '100%', marginTop: 8, position: 'relative'}}>
          <Strategy open={openModal.strategy} closeModal={() => handleOpenModal("strategy") } top={topModal} topClick={() => setTopModal("strategy")} />
          <News open={openModal.news} closeModal={() => handleOpenModal("news")} top={topModal} topClick={() => setTopModal("news")} />
          <Events open={openModal.eve} closeModal={() => handleOpenModal("eve")} top={topModal} topClick={() => setTopModal("eve")} />
        </Box>
      </Modal>
    </>
  );
};

export default TopAppBar;
