import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-date-fns';
import { Box, TextField, Typography } from '@mui/material';

Chart.register(annotationPlugin);

const OiChart = ({ oiData=[], fut_price, lot_size=1 }) => {
  const canvasRef = useRef(null);
  const chartRef = useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [points, setPoints] = React.useState({ labels: [], put: [], call: []});
  const [strikes, setStrikes] = React.useState({count: 1, max: 1 });

  useEffect(() => {
    const labels = oiData.map(d => d?.strike);
    setPoints({ labels, call: oiData.map(d => d?.l_oi_c/lot_size), put: oiData.map(d => d?.l_oi_p/lot_size) });
    setStrikes(s => ({...s, count: Math.ceil(labels.length / 2), max: labels.length }));
  }, [oiData]);

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');
    chartRef.current && chartRef.current.destroy();
    const point_length = points.labels.length;

    if (point_length){
      const midIndex = Math.ceil(point_length/2);
      const strikesCount = Number(strikes.count ?? 0);
      const strikesMid = Math.ceil(strikesCount/2);
      
      const first = midIndex - strikesMid;
      const last = midIndex + strikesCount - strikesMid;
      
      chartRef.current = new Chart(ctx, {
        data: {
          labels: points.labels.slice(first, last),
          datasets: [
            {
              label: "Call OI",
              type: 'bar',
              data: points.call.slice(first, last),
              backgroundColor: "rgba(255, 99, 132, 0.5)", // Red for calls
              borderColor: "rgba(255, 99, 132, 1)",
              borderWidth: 1,
            },
            {
              label: "Put OI",
              type: 'bar',
              data: points.put.slice(first, last),
              backgroundColor: "rgba(75, 192, 192, 0.5)", // Green for puts
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          // indexAxis: 'y',
          scales: {
            x: {
              type: 'linear',
              title: {
                display: true,
                text: 'Strikes',
              },
            },
            y: {
              title: {
                display: true,
                text: 'OI (Open Interest)',
              },
            },
          },
          plugins: {
            annotation: {
              annotations: {
                strikeLine: {
                  display: Boolean(fut_price),
                  label: {
                    type: 'label',
                    display: true,
                    position: 'end',
                    color: 'grey',
                    font: {size: 16}, 
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    content: `₹${fut_price}`,
                  },
                  type: 'line',
                  xMin: fut_price,
                  xMax: fut_price,
                  borderColor: 'grey',
                  borderWidth: 1,
                  borderDash: [4, 4],
                },
              },
            },
            tooltip: {
              enabled: true,
              mode: 'nearest',
              intersect: false,
              displayColors: false,
              callbacks: {
                title: ()=>null,
                label: (context) => {
                  const textArr = [];
                  context.chart.legend.legendItems.forEach((l)=> {
                    !l.hidden && textArr.push(`${l.text}: ${context.chart.data.datasets[l.datasetIndex].data[context.dataIndex]}`);
                  });

                  textArr.push(`Strike: ${context.label}`);
                  return textArr;
                },
              },
            },
          },
        },
      });

    }

    return () => chartRef.current && chartRef.current.destroy();
  }, [strikes, points]);

  return <>
    {loading && <Box sx={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Typography>Loading...</Typography></Box>}
    <Box sx={{width: '100%', p:0, display: 'flex',}}>
      <div style={{width: '90%', height: '375px' }}>
        <canvas ref={canvasRef} />
      </div>
      <div style={{width: '10%'}}>
        <TextField size='small' type='number' label="Strikes" title="Strikes" variant="outlined" value={strikes?.count ?? 0} onChange={(e) => setStrikes(s => ({...s, count: e.target.value})) } inputProps={{min: 1, max: strikes.max, style: { textAlign: 'center' }}} />            
      </div>
    </Box>
  </>
};

export default OiChart;
