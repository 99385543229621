import React from 'react';
import { Box, FormControl, Button, InputLabel, Select, MenuItem, Slider, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { histAPI, socketAPI, kiteAPI } from "../../../utils/axiosInstance";
import { createAlert } from "../../../features/slices/alert/TransitionAlertsSlice";
import SelectInstrument from "../../SelectInstrument/SelectInstrument";
import { useDispatch } from 'react-redux';
import OiChart from "../../Charts/OiChart";

export default function OiAnalysis(){
  const dispatch = useDispatch();
  const [loadingOptions, setLoadingOptions] = React.useState(false);
  const [loadingExp, setLoadingExp] = React.useState(false);
  const [stockData, setStockData] = React.useState({lot_size: 1, fair_price: 0});
  const [expList, setExpList] = React.useState([]);
  const [dateList, setDateList] = React.useState([]);
  const [oiData, setOiData] = React.useState([]);
  const [instrumentList, setInstrumentList] = React.useState([]);
  const [instrument, setInstrument] = React.useState({ instrument:"NIFTY", expiry: null, date:null });
  const [slider, setSlider] = React.useState([555, 930]);
  const [sliderT, setSliderT] = React.useState([]);
  const [sliderMax, setSliderMax] = React.useState(930);
  
  React.useEffect(() =>{
    const timeoutId = setTimeout(() => { 
      setSliderT(slider);
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [slider]);

  const shouldDisableDate = React.useCallback(
    (date) => {
      const dateToCheck = new Date(date).setHours(0, 0, 0, 0);
      return !dateList.some(
        (availableDate) => 
          new Date(availableDate).setHours(0, 0, 0, 0) === dateToCheck
      );
  }, [dateList]);

  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60); const mins = minutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
  };

  const getOI = React.useCallback(() => {
    if(instrument.date){
      const date = instrument.date?.toISOString()?.split('T')?.[0];
      
      date && slider.length &&
      (async () => {
        try {
          const r = await histAPI.post('charts/get-oi-data', {
            bucket: instrument.instrument,
            measurement: instrument.expiry,
            start: `${date}T${formatTime(sliderT[0])}:00+05:30`,
            stop: `${date}T${formatTime(sliderT[1])}:00+05:30`
          });
          if(r.data?.success){
            setOiData(r.data.data);
            if(r.data.data.length === 0){
              dispatch(createAlert({message: "No data found, Try with different options...", type: 'error', timeout: 3000}));
              return;
            }
          }else{
            setOiData([]);
          }
        } catch (error) {
          setOiData([]);
        }
      })();
    }
  }, [instrument.date, sliderT]);

  const sliderUpdate = (dt) => {
    let clampedMaxTime = 930;
    const now = new Date();
    const today = (now.toISOString()?.split("T")?.[0]) ?? "";
    const date = (dt.toISOString()?.split("T")?.[0]) ?? "";
    if (date === today){
      const currentMinutes = now.getHours() * 60 + now.getMinutes();
      clampedMaxTime = Math.min(currentMinutes, 930);
    }
    setSlider((v) => v[1] > clampedMaxTime ? [v[0], clampedMaxTime] : v);
    setSliderMax(clampedMaxTime);
  };

  const fetchOptions = React.useCallback(async (search = "", instrument="", expiry="") => {
    setLoadingOptions(true);
    try {
      const r = await histAPI.post('charts/get-options', {
        bucket: instrument,
        measurement: expiry,
        search,
      });
      if (r.data?.success){
        if (!instrument){
          setInstrumentList(r.data?.data ?? []);
        }else if (!expiry){
          setExpList(r.data?.data ?? []);
        }else{
          const d = []
          for (const n of r.data?.data ?? []){
            d.push(new Date(n));
          }
          setDateList(d);
        }
      }
    } catch (error) {
      setLoadingOptions(false);
    }
  }, []);

  const getExpList = React.useCallback(async() => {
    if (instrument.instrument){
      try {
        setLoadingExp(true);
        const r = await socketAPI.get(`instruments/expiry-list/${instrument.instrument}?type=d`);
        if (r?.data?.success && Array.isArray(r.data?.data) && r.data?.data.length){
          setExpList((r.data?.data).map(d => d.name));
          setInstrument((ins)=>{
            if (ins.expiry === null){
              return({...ins, expiry: r.data?.data[0]?.name});
            }
            return ins;
          });
        }
        
      } catch (error) {
      }
      setLoadingExp(false);
    }
  }, [instrument.instrument]);

  React.useEffect(()=>{ getOI(); }, [getOI]);
  React.useEffect(()=>{ getExpList(); }, [getExpList]);
  React.useEffect(()=>{ fetchOptions("NIF"); }, []);

  React.useEffect(()=>{ 
    if(dateList?.length){
      sliderUpdate(dateList[0]);
      setInstrument((ins) => ({...ins, date: dateList[0]}));
    }
  }, [dateList]);

  const getQuote = React.useCallback(async()=>{
    try {
      if (instrument.instrument && instrument.expiry){
        const data = await kiteAPI.get(`/quote/get?t=F&i=${instrument.instrument}${instrument.expiry}FUT`);
        if (data.data.success){
          setStockData({ fair_price: data.data?.data?.price ?? 0, lot_size: data.data?.data?.lotSize ?? 1 });
        }else{
          setStockData({ fair_price: 0, lot_size: 1 });
        } 
      }else{
        setStockData({ fair_price: 0, lot_size: 1 });
      }
    } catch (error) {
      setStockData({ fair_price: 0, lot_size: 1 });
    }
  }, [instrument.instrument, instrument.expiry]);

  React.useEffect(()=>{ 
    if(instrument.expiry){
      fetchOptions(null, instrument.instrument, instrument.expiry);
      getQuote()
    }
  }, [instrument.instrument, instrument.expiry]);

  return (
    <Box component={'div'}>
      <Box component="form" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', m: 'auto 10px auto 5px', p: '0.7rem', justifyContent: 'center' }}>
        <FormControl size='small' sx={{m: '0.1rem', width: '13.2rem'}}>
          <SelectInstrument value={instrument.instrument} width={'13rem'} label={"Underlying"} placeholder="Search underlying..." loading={loadingOptions} list={instrumentList} getList={ se => fetchOptions(se) } onChange={(v) => setInstrument({...instrument, instrument: v, expiry: "", date: "" })}/>
        </FormControl>
        <FormControl size='small' sx={{m: '0.1rem', width: '13.2rem'}} disabled={!instrument.instrument} >
          <InputLabel id="expiry-label">Expiry</InputLabel>
          <Select labelId="expiry-label" label={'Expiry'} value={instrument.expiry ?? ""} onChange={(e) => setInstrument((ins)=>({...ins, expiry: e.target.value}))}>
          { loadingExp ? <MenuItem key={`exp_load`} disabled value={'load'}>Loading...</MenuItem> : expList.map((d, i)=> <MenuItem key={`exp_sel_${i}`} value={d}>{d}</MenuItem>) }
          </Select>
        </FormControl>
        {/* <FormControl size='small' sx={{m: '0.1rem', width: '13.2rem'}} disabled={!instrument.instrument} >
          <SelectInstrument value={instrument.expiry} width={'13rem'} label={"Expiry"} placeholder="Search expiry..." loading={loadingOptions} list={expList} getList={ se => se && fetchOptions(se, instrument.instrument) } onChange={(v) => setInstrument({...instrument, expiry: v ? v : "", date: "" })}/>
        </FormControl> */}
        <FormControl size='small' sx={{m: '0.1rem', width: '13rem'}} disabled={!instrument.expiry} >
         <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker shouldDisableDate={shouldDisableDate} disabled={!instrument.expiry} x={{m: '0.1rem'}} slotProps={{ textField: { size: 'small' } }} format="dd-MM-yyyy" onChange={date => { sliderUpdate(date); setInstrument({...instrument, date });}} autoComplete='false' label="Date" value={instrument.date} />
          </LocalizationProvider>
        </FormControl>
        <Box sx={{display: 'flex', width: '75%', alignItems: 'center', p: 0 }}>
          <Typography variant="body1" sx={{width: '10%', display: 'flex', justifyContent: 'flex-end', paddingRight: '1rem' }}>{formatTime(slider[0])}</Typography> 
          <Slider min={555} max={sliderMax} sx={{width: '80%'}} value={slider} onChange={(_, v) => setSlider(v) } valueLabelDisplay="auto" valueLabelFormat={formatTime}/> 
          <Typography variant="body1" sx={{width: '10%', paddingLeft: '1rem'}}>{formatTime(slider[1])}</Typography>
        </Box>
      </Box>

      <Box width='70%' margin={'0 auto'} display='flex' alignItems='center' flexDirection='column'>
        <OiChart oiData={oiData} fut_price={stockData.fair_price} lot_size={stockData.lot_size} />
      </Box>
    </Box>
  )
}