import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-date-fns';
import { calculatePayoff } from "../../utils/globalFunctions";
import { Box, Typography } from '@mui/material';
import { socketAPI } from "../../utils/axiosInstance";

Chart.register(annotationPlugin);

const PayoffChart = ({ position=[], fut_price=0, strikeList=[], expiry=null, instrument=null }) => {
  const canvasRef = useRef(null);
  const chartRef = useRef(null);
  const [breakEvenPoints, setBreakEvenPoints] = React.useState([]);
  const [strikes, setStrikes] = React.useState(strikeList);
  const [loading, setLoading] = React.useState(false);

  const getStrikeList = React.useCallback(async (instrument, expiry)=>{
    setLoading(true);
    const r = await socketAPI.post('chain/strike-list', {instrument, expiry});
    setLoading(false);
    setStrikes(r.data?.data?.length ? r.data.data : [])
  }, []);

  useEffect(() => {
    if(instrument && expiry){
      getStrikeList(instrument, expiry);
    }
    else{
      setStrikes(strikeList);
    }
  }, [position]);

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');
    if (chartRef.current) {
      chartRef.current.destroy();
    }
    
    let priceRange = [...strikes, fut_price].sort((a, b) => a - b);
    const bp = [];
    const index = priceRange.indexOf(fut_price);
    priceRange = priceRange.filter((_, i) => i >= index-14 && i <= index+14);
    const points = priceRange.map((cp) => position.reduce((acc, p) => Math.round(acc + calculatePayoff(p.it, cp, p.qty, p.st !== -1 ? p.st : p.ffp, p.p)), 0));

    for (let i = 1; i < points.length; i++) {
      if ((points[i - 1] < 0 && points[i] > 0) || (points[i - 1] > 0 && points[i] < 0)) {
        const x1 = priceRange[i - 1];
        const x2 = priceRange[i];
        const y1 = points[i - 1];
        const y2 = points[i];
        const breakEven = Math.round(x1 + (0 - y1) * (x2 - x1) / (y2 - y1));
        bp.push(breakEven);
      }
    }

    setBreakEvenPoints(bp);

    priceRange = [...priceRange, ...bp].sort((a, b) => a - b);
    bp.forEach(element => {
      points.splice(priceRange.indexOf(element), 0, 0);
    });

    chartRef.current = new Chart(ctx, {
      data: {
        labels: priceRange,
        datasets: [
          {
            label: 'Pay-off',
            data: points,
            type: 'line',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
            tension: 0.3,
            fill: {
              target: 'origin',
              above: 'rgba(0, 255, 0, 0.3)',
              below: 'rgba(255, 0, 0, 0.3)',
            },
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          x: {
            type: 'linear',
            title: {
              display: true,
              text: 'Underlying price at Expiry (₹)',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Profit / Loss (₹)',
            },
          },
        },
        plugins: {
          annotation: {
            annotations: {
              strikeLine: {
                type: 'line',
                xMin: fut_price,
                xMax: fut_price,
                borderColor: 'grey',
                borderWidth: 1,
                borderDash: [4, 4],
              },
              breakEven: {
                type: 'line',
                yMin: 0,
                yMax: 0,
                borderColor: 'grey',
                borderWidth: 1,
                borderDash: [4, 4],
              }
            },
          },
          tooltip: {
            enabled: true,
            mode: 'nearest',
            intersect: false,
            callbacks: {
              title: ()=>null,
              label: (context) => [
                `Payoff: ₹${context.parsed.y.toFixed(2)}`,
                `@${context.label}`,
              ],
            },
          },
          legend: {
            display: false,
          },
        },
      },
    });

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [position, fut_price, strikes]);

  return <>
    <div style={{height: '350px', width: '70vw'}}>
      {loading && <Box sx={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Typography>Loading...</Typography></Box>}
      <Box sx={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-evenly'}}>
        <Typography sx={{fontSize: '1.1rem'}}>Current price: {fut_price}</Typography>
        <Typography sx={{fontSize: '1.1rem'}}>Break even: {breakEvenPoints.map((b, i) => { const chg = (b-fut_price)*100/b; return(<Typography component={'span'} sx={{color: chg < 0 ? 'red' : 'green'}}> {parseFloat(chg).toFixed(2)}% ({b}){breakEvenPoints.length !== (i+1) ? ', ' : ''}</Typography>);}
        )}</Typography>
      </Box>
      <canvas ref={canvasRef} />
    </div>
  </>
};

export default PayoffChart;
