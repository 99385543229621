import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { IconButton, Tabs, Tab, Typography, Box, OutlinedInput } from '@mui/material';
import { socketAPI } from "../../utils/axiosInstance";
import LiveDataTable from './LiveDataTable';
import { useDispatch, useSelector  } from 'react-redux';
import { getList, addInstrument, clearSearch } from '../../features/slices/search/searchSlice';
import SelectInstrument from "../SelectInstrument/SelectInstrument";

export function CustomLiveDataTable() {
  const [titles, setTittles] = React.useState([]);
  const [currentList, setCurrentList] = React.useState("");
  const [add, setAdd] = React.useState(false);
  const [name, setName] = React.useState("");

  const getList = React.useCallback(async() => {
    const response = await socketAPI.get('list/get');
    setTittles(response.data?.data ?? []);
    if (response.data?.data?.length){
      currentList === "add" ? setCurrentList(response.data?.data[0]?.id): setCurrentList(response.data?.data[response.data.data.length - 1]?.id)
    }
  }, [])

  const addList = React.useCallback(async(name) => {
    await socketAPI.get(`list/add?name=${name}`);
    getList();
    setAdd(false);
  }, [])

  const delList = React.useCallback(async(id) => {
    await socketAPI.delete(`list/remove?id=${id}`);
    getList();
  }, [])

  React.useEffect(() => {
    getList();
  }, []);
  
  const handleChange = (e, v) => {
    if(v === 'add'){
      setAdd(true);
      return;
    }
    setCurrentList(v);
  }

  return (
    <Box sx={{width: '95%', padding: '1rem', margin: '0px auto'}}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2, p:0, m:0 }}>
        <Tabs value={currentList} onChange={handleChange} textColor="secondary" indicatorColor="secondary" variant={"scrollable"}>
          {
            titles.map((title, i) =>
              <Tab key={`tab_${i}`} value={title.id} label={title?.name} icon={ title.id === currentList && <IconButton onClick={()=> delList(title.id)} size={'small'}>< CloseIcon color={'error'}/></IconButton> } iconPosition="end"/>
          ) 
          }
          {
            titles?.length < 5 && !add && <Tab key={`tab_add}`} title='Add new list...' value={'add'} icon={<AddIcon />} />
          }
        </Tabs>
        {
          add &&
          <OutlinedInput sx={{m:'1rem'}} size='small' id="outlined-basic" value={name} onChange={(e)=>{setName(e.currentTarget.value)}} endAdornment={
            <>
              <IconButton size={'small'}><DoneIcon sx={{ color: 'green', mr: 1, my: 0.5 }} onClick={() => name && addList(name)} /> </IconButton>
              <IconButton size={'small'}><CloseIcon sx={{ color: 'red', mr: 1, my: 0.5 }} onClick={()=> setAdd(false)} /></IconButton>
            </>
          } />
        }
      </Box>
    </Box>
  )
}

export const UserDataTable = ({ pref=require('../../jsons/tables/user_ui.json'), columnVisibility, setDeleteOptions, setSelectedItems }) => {
  const dispatch = useDispatch();
  const searchList  = useSelector((state) => state.search.instruments.list);
  const searchFetch = useSelector((state) => state.search.instruments.status);

  const add_instrument = (instrument) => {
    dispatch(addInstrument(instrument));
    dispatch(clearSearch(instrument));
  }

  return (
    <>
      <Typography sx={{cursor:'default', position: 'absolute', textAlign: 'center', width: '100%'}} fontSize={pref.titleFontSize} fontWeight={700} fontFamily={'emoji'} color={pref.titleFontColor}>{pref.title}</Typography>
      <Box component={'div'} sx={{display: 'flex', p: 0, justifyContent:'flex-end', m: 'auto 10px auto 5px'}}>
        <SelectInstrument width={'25vw'} label={'Search Instrument...'} placeholder={"Add Instrument to price action list..."} loading={searchFetch === 'loading'} list={searchList} getList={(search)=> { dispatch(getList({search})); }} onChange={add_instrument}/>
      </Box>
      <LiveDataTable pref={pref} columnVisibility={columnVisibility} setDeleteOptions={setDeleteOptions} setSelectedItems={setSelectedItems} />
    </>
  );
}