import axios from 'axios';

export const apiUser = axios.create({ baseURL: process.env.REACT_APP_BASE_URL_USER, withCredentials: true });
export const tradeUser = axios.create({ baseURL: process.env.REACT_APP_BASE_URL_TRADE_USER, withCredentials: true });
export const socketAPI = axios.create({ baseURL: process.env.REACT_APP_BASE_URL_SOCKET_API, withCredentials: true });
export const kiteAPI = axios.create({ baseURL: process.env.REACT_APP_BASE_URL_KITE, withCredentials: true });
export const histAPI = axios.create({ baseURL: process.env.REACT_APP_BASE_URL_HIST, withCredentials: true });

// [apiUser, tradeUser, socketAPI, kiteAPI, histAPI].forEach(instance => instance.interceptors.response.use(
//   response => response, 
//   error => (error.response && error.response.status === 401) ? window.location.reload() : Promise.reject(error))
// );