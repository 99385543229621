import React from 'react';
import { Box, Tabs, Tab, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EcoCalendar from "../../Tables/EcoCalendar";
import EventsTable from '../../Tables/EventsTable';
import QtrResults from '../../Tables/QtrResults';
import { useResizeDetector } from 'react-resize-detector';
import Draggable from 'react-draggable';
import zIndex from '@mui/material/styles/zIndex';

let modalEventsWH = localStorage.getItem('modalEventsWH');
modalEventsWH = modalEventsWH? JSON.parse(modalEventsWH) : null;
let modalEventsXY = localStorage.getItem('modalEventsXY');
modalEventsXY = modalEventsXY? JSON.parse(modalEventsXY) : null; 

export default function Events({open, closeModal, topClick, top=""}) {
    const [value, setValue] = React.useState('ec');
    const rootRef = React.useRef(null);
    const { width, height } = useResizeDetector({skipOnMount: true, targetRef: rootRef});
    const modalStyle = {
        position: 'absolute',
        left: modalEventsXY?.left ?? `calc(50% - ${modalEventsWH?.width ? (modalEventsWH.width)/2+'px' : '40vw'})`,
        top: modalEventsXY?.top ?? '10vh',
        transform: 'translate(-50%, -50%)',
        width: modalEventsWH?.width ?? '80vw',
        height: modalEventsWH?.height ?? 'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        maxWidth: '80vw',
        maxHeight: '85vh',
        resize: 'both',
        overflow: 'auto',
        display: open? 'block' : 'none',
        zIndex: top === "eve" ? 1 : 0
    };

    React.useEffect(() => {
        if (width && height){
          localStorage.setItem('modalEventsWH', JSON.stringify({width, height}));
        }
      }, [width, height]);
    
    const handleStopDrag = () => {
        const rect = rootRef.current.getBoundingClientRect();
        localStorage.setItem('modalEventsXY', JSON.stringify(rect));
    };

    const handleChange = (_, newValue) => setValue(newValue);

  return (
    <Draggable handle=".drag-handle" nodeRef={rootRef} onStop={handleStopDrag} bounds="parent">
        <Box component={'div'} ref={rootRef} sx={modalStyle} onClick={topClick}>
            <Box className="drag-handle" id="draggable-modal-title" sx={{display: 'flex', width: '100%', alignItems: 'center', cursor: 'grab', zIndex: 10}}>
                <Typography>Events</Typography>
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                    <IconButton onClick={closeModal} onTouchStart={closeModal}><CloseIcon color="error" /></IconButton>
                </Box>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb:2 }}>
                <Tabs centered value={value} onChange={handleChange} aria-label="MWPL tabs">
                    <Tab label="Economic Calender" value={'ec'}/>
                    <Tab label="Corp. Action" value={'corp_action'}/>
                    <Tab label="Board Meeting" value={'comp_ev'}/>
                    <Tab label="Qtr Results" value={'qr'}/>
                </Tabs>
            </Box>
            {
                value === 'ec'? <EcoCalendar /> : value === 'corp_action'? <EventsTable eventType={value} /> : value === 'qr'? <QtrResults/> : value === 'comp_ev'? <EventsTable eventType={value} /> :  null
            }
        </Box>
    </Draggable>
  )
}